import { createSlice } from '@reduxjs/toolkit';

const polygonslice = createSlice({
  name: 'polygon',
  initialState: {
    drawing: false,
    onlyOne: false,
    drawingGeometry: [],
    editting: false,
    editIdx: null,
    pois: [],
    opportunityArea: [],
    livingArea: [],
    cityBoundary: [],
    storeLocation: [],
    viewing: false,
    selectedPolygonId: null,
    drawingPoint: false,
  },
  reducers: {
    setViewing: (state, action) => {
      state.viewing = action.payload;
  },
    setDrawing: (state, action) => {
        state.drawing = action.payload;
    },
    setDrawingGeometry: (state, action) => {
        state.drawingGeometry = action.payload;
    },
    setOnlyOne: (state, action) => {
      state.onlyOne = action.payload;
    },
    setEditting: (state, action) => {
        state.editting = action.payload;
    },
    setEditIdx: (state, action) => {
        state.editIdx = action.payload;
    },
    setPois: (state, action) => {
      state.pois = action.payload;
    },
    setOpportunityArea: (state, action) => {
      state.opportunityArea = action.payload;
    },
    setLivingArea: (state, action) => {
      state.livingArea = action.payload;
    },
    setCityBoundary: (state, action) => {
      state.cityBoundary = action.payload;
    },
    setStoreLocation: (state, action) => {
      state.storeLocation = action.payload;
    },
    setSelectedPolygonId: (state, action) => {
      state.selectedPolygonId = action.payload;
    },
    setDrawingPoint: (state, action) => {
      state.drawingPoint = action.payload;
    },
  },
});

export default polygonslice.reducer;

export const { 
  setDrawing, 
  setDrawingGeometry, 
  setOnlyOne, 
  setEditting, 
  setEditIdx, 
  setPois, 
  setOpportunityArea, 
  setLivingArea, 
  setCityBoundary, 
  setStoreLocation,
  setSelectedPolygonId,
  setViewing,
  setDrawingPoint,
} = polygonslice.actions;
import { createSlice } from '@reduxjs/toolkit';

const tsslice = createSlice({
  name: 'timeseries',
  initialState: {
    isTimeSeriesRunning: false,

  },
  reducers: {
    setTsFlag: (state, action) => {
      state.isTimeSeriesRunning = action.payload;
    },
  },
});

    
export default tsslice.reducer;

export const { setTsFlag } = tsslice.actions;

import deepmerge from 'deepmerge';
import { createTheme, adaptV4Theme } from '@mui/material';
import { cartoThemeOptions } from '@carto/react-ui';
import { grey } from '@mui/material/colors';

const customTheme = {
  palette: {
    primary: {
      main: '#3643BA',
    },
    secondary: {
      main: '#7AFFA6',
    },
    orange: {
      main: '#FF7900',
      light: '#F28500',
    },
    delete: {
      main: '#FF2C2C',
    },
    whitish: {
      main: grey[100],
    }
  },
};

const theme = createTheme(adaptV4Theme(deepmerge(cartoThemeOptions, customTheme)));

export default theme;

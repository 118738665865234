import { POSITRON } from '@carto/react-basemaps';
import { API_VERSIONS } from '@deck.gl/carto';

export const initialState = {
  viewState: {
    longitude: -0.5816497743096974,
    latitude: 44.82835515814133, 
    zoom: 8,
    pitch: 0,
    bearing: 0,
    dragRotate: false,
  },
  basemap: POSITRON,
  credentials: {
    apiVersion: API_VERSIONS.V3,
    apiBaseUrl: 'https://carto.ure.decathlon.net/api',
  },
  googleApiKey: 'AIzaSyAnxA2uQns5HNbCC4nx6u1bzfoEcC_s_R0', // only required when using a Google Basemap
  googleMapId: '', // only required when using a Google Custom Basemap
  accountsUrl: 'https://app.carto.com/',
  oauth: {
    namespace: 'http://app.carto.com/',
    domain: 'auth.carto.com',
    //clientId: 'h72XPqyqNRhvxBhe93LFhTogrPApClbN',
    clientId: 'RrFTtdJeT3PEgS8dGyhptggqtcB8kPlK', // type here your application clientId
    organizationId: 'org_oZEULQJr4VoN1tKg', // organizationId is required for SSO
    //organizationId: '',
    scopes: [
      'read:current_user',
      'update:current_user',
      'read:connections',
      'write:connections',
      'read:maps',
      'write:maps',
      'read:account',
      'admin:account',
    ],
    audience: 'carto-cloud-native-api',
    authorizeEndPoint: 'https://carto.com/oauth2/authorize', // only valid if keeping https://localhost:3000/oauthCallback
  },
};

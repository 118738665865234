import { createSlice } from '@reduxjs/toolkit';

const filterslice = createSlice({
  name: 'filter',
  initialState: {
    days: false,
    types: false,
    hours: false,
    agepct0_14: false,
    agepct15_59: false,
    agepct60: false,
  },
  reducers: {
    setDaysFlag: (state, action) => {
      state.days = action.payload;
    },
    setTypesFlag: (state, action) => {
      state.types = action.payload;
    },
    setHoursFlag: (state, action) => {
      state.hours = action.payload;
    },
    setAgePct0_14Flag: (state, action) => {
      state.agepct0_14 = action.payload;
    },
    setAgePct15_59Flag: (state, action) => {
      state.agepct15_59 = action.payload;
    },
    setAgePct60Flag: (state, action) => {
      state.agepct60 = action.payload;
    },
  },
});

    
export default filterslice.reducer;

export const { setDaysFlag, setTypesFlag, setHoursFlag, setAgePct0_14Flag, setAgePct15_59Flag, setAgePct60Flag } = filterslice.actions;

import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'app',
  initialState: {
    error: null,
    bottomSheetOpen: false,
    drawing: false,
    currentTab: null,
    drawingGeometry: [],
    opportunityArea: [],
    livingArea: [],
    cityBoundary: [],
    storeLocation: [],
    onlyOne: false,
    rightbarTab: 0, 
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setBottomSheetOpen: (state, action) => {
      state.bottomSheetOpen = action.payload;
    },
    setDrawing: (state, action) => {
      state.drawing = action.payload;
    },
    setOpportunityArea: (state, action) => {
      state.opportunityArea = action.payload;
    },
    setLivingArea: (state, action) => {
      state.livingArea = action.payload;
    },
    setCityBoundary: (state, action) => {
      state.cityBoundary = action.payload;
    },
    setDrawingGeometry: (state, action) => {
      state.drawingGeometry = action.payload;
    },
    setStoreLocation: (state, action) => {
      state.storeLocation = action.payload;
    },
    setOnlyOne: (state, action) => {
      state.onlyOne = action.payload;
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setRightBarTab: (state, action) => {
      state.rightbarTab = action.payload;
    },
  },
});

export default slice.reducer;

export const setError = (payload) => ({ type: 'app/setError', payload });
export const setBottomSheetOpen = (payload) => ({
  type: 'app/setBottomSheetOpen',
  payload,
});
export const setOpportunityArea = (payload) => ({ type: 'app/setOpportunityArea', payload });
export const setLivingArea = (payload) => ({ type: 'app/setLivingArea', payload });
export const setCityBoundary = (payload) => ({ type: 'app/setCityBoundary', payload });
export const setDrawingGeometry = (payload) => ({ type: 'app/setDrawingGeometry', payload });
export const setStoreLocation = (payload) => ({ type: 'app/setStoreLocation', payload });
export const setDrawing = (payload) => ({ type: 'app/setDrawing', payload });
export const setOnlyOne = (payload) => ({ type: 'app/setOnlyOne', payload });
export const setCurrentTab = (payload) => ({ type: 'app/setCurrentTab', payload });
export const setRightBarTab = (payload) => ({ type: 'app/setRightBarTab', payload });

import Header from 'components/common/Header';
import { useSelector } from 'react-redux';

export default function DefaultView({ children }) {
  const hideHeader = useSelector((state) => state.layer.hideHeader);

  return (
    <>
      {!hideHeader && <Header />}
      {children}
    </>
  );
}
